import { makeAutoObservable } from "mobx";
import { changeLanguage } from "i18next";
import type { LangType } from "helpers/types";

class Store {
  loading: boolean = false;
  lang: LangType = "en";
  error: string = "";
  widgetLoadError: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  startLoading = () => {
    this.loading = true;
  };

  endLoading = () => {
    this.loading = false;
  };

  setError = (err: string) => {
    this.error = err;
  };

  setWidgetError = () => {
    this.widgetLoadError = true;
  };

  setLang = (lang: LangType) => {
    changeLanguage(lang);
    this.lang = lang;
  };
}

const globalStore = new Store();
export default globalStore;
