import { makeAutoObservable } from "mobx";
import type {
  ExistPhazeProductInterface,
  CredictcoCardDetails,
} from "helpers/types";
class Store {
  giftCardsList: ExistPhazeProductInterface[] | null = null;
  cryptoCardsList: CredictcoCardDetails[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setGiftCardsList = (giftCards: ExistPhazeProductInterface[]) => {
    this.giftCardsList = giftCards;
  };

  setCryptoCardsList = (giftCards: CredictcoCardDetails[]) => {
    this.cryptoCardsList = giftCards;
  };
}

const cardsStore = new Store();
export default cardsStore;
