import { ReactNode } from "react";
import { ChartType } from "./types";
import { fiatIcons } from "./fiatOptions";
import { ReactComponent as Usdt } from "assets/icons/coins/USDT.svg";
import { ReactComponent as Usdc } from "assets/icons/coins/USDC.svg";
import { ReactComponent as Btc } from "assets/icons/coins/BTC.svg";
import { ReactComponent as Trx } from "assets/icons/coins/TRX.svg";
import { ReactComponent as Eth } from "assets/icons/coins/ETH.svg";
import { ReactComponent as Ada } from "assets/icons/coins/ADA.svg";
import { ReactComponent as Algo } from "assets/icons/coins/ALGO.svg";
import { ReactComponent as Atom } from "assets/icons/coins/ATOM.svg";
import { ReactComponent as Dot } from "assets/icons/coins/DOT.svg";
import { ReactComponent as Flow } from "assets/icons/coins/FLOW.svg";
import { ReactComponent as Flr } from "assets/icons/coins/FLR.svg";
import { ReactComponent as Grt } from "assets/icons/coins/GRT.svg";
import { ReactComponent as Kava } from "assets/icons/coins/KAVA.svg";
import { ReactComponent as Ksm } from "assets/icons/coins/KSM.svg";
import { ReactComponent as Matic } from "assets/icons/coins/MATIC.svg";
import { ReactComponent as Mina } from "assets/icons/coins/MINA.svg";
import { ReactComponent as Scrt } from "assets/icons/coins/SCRT.svg";
import { ReactComponent as Sol } from "assets/icons/coins/SOL.svg";
import { ReactComponent as Xtz } from "assets/icons/coins/XTZ.svg";
import { ReactComponent as Dai } from "assets/icons/coins/DAI.svg";
import { ReactComponent as Link } from "assets/icons/coins/LINK.svg";
import { ReactComponent as Uni } from "assets/icons/coins/UNI.svg";
import { ReactComponent as Funt } from "assets/icons/coins/FUNT.svg";
import { ReactComponent as Cru } from "assets/icons/coins/CRU.svg";
import { ReactComponent as Untd } from "assets/icons/coins/UNTD.svg";
import { ReactComponent as Usdu } from "assets/icons/coins/USDU.svg";
import { ReactComponent as Bnb } from "assets/icons/coins/BNB.svg";
import { ReactComponent as Stbu } from "assets/icons/coins/STBU.svg";
import { ReactComponent as Gxag } from "assets/icons/coins/GXAG.svg";
import { ReactComponent as Ton } from "assets/icons/coins/TON.svg";
import { ReactComponent as Eurt } from "assets/icons/coins/EURT.svg";
import { ReactComponent as Xaut } from "assets/icons/coins/XAUT.svg";
import { ReactComponent as Dogs } from "assets/icons/coins/DOGS.svg";
import virtualGupImage from "assets/images/cryptoCards/virtualGTI.png";
import virtualVikinksImage from "assets/images/cryptoCards/virtualVikings.png";
import physicalStandardGTI from "assets/images/cryptoCards/physicalStandardGTI.png";
import physicalGoldGTI from "assets/images/cryptoCards/physicalGoldGTI.png";
import rwaCard from "assets/images/cryptoCards/rwaCard.png";
import foxpayCard from "assets/images/cryptoCards/foxpay.png";

export const siteName = "FNT Crypto";
export const supportUrl = "info@fntworld.io";

export const mainFont = "'Inter', 'Montserrat', sans-serif";

export const notVerifiedMessage = "not verified";
export const twoFAMessage = "2FA required";

export const krakenFee = 0.0025;

export const colors = {
  white: "#fff",

  primary_10: "#fff7ef",
  primary_25: "#fffcf5",
  primary_50: "#fffaeb",
  primary_100: "#fef0C7",
  primary_200: "#fedf89",
  primary_400: "#fdb122",
  primary_500: "#f79009",
  primary_600: "#dc6803",
  primary_700: "#b54708",

  gray_25: "#fcfcfd",
  gray_50: "#f9fafb",
  gray_100: "#f2f4f7",
  gray_200: "#eaecf0",
  gray_300: "#d0d5dd",
  gray_400: "#98a2b3",
  gray_500: "#667085",
  gray_600: "#475467",
  gray_700: "#344054",
  gray_900: "#101828",

  blue_50: "#f0f9ff",
  blue_700: "#026aa2",

  success_50: "#ecfdf3",
  success_100: "#dcfae6",
  success_200: "#abefc6",
  success_400: "#17b26a",
  success_500: "#17b26a",
  success_600: "#079455",
  success_700: "#067647",

  error_50: "#fef3f2",
  error_200: "#fecdca",
  error_400: "#f04438",
  error_500: "#f04438",
  error_600: "#d92d20",
  error_700: "#b42318",
};

export const currencyIcons: { [k: string]: ReactNode } = {
  USDT: <Usdt />,
  USDC: <Usdc />,
  BTC: <Btc />,
  TRX: <Trx />,
  ETH: <Eth />,
  ADA: <Ada />,
  ALGO: <Algo />,
  ATOM: <Atom />,
  DOT: <Dot />,
  FLOW: <Flow />,
  FLR: <Flr />,
  GRT: <Grt />,
  KAVA: <Kava />,
  KSM: <Ksm />,
  MATIC: <Matic />,
  MINA: <Mina />,
  SCRT: <Scrt />,
  SOL: <Sol />,
  XTZ: <Xtz />,
  DAI: <Dai />,
  UNI: <Uni />,
  LINK: <Link />,
  FUNT: <Funt />,
  CRU: <Cru />,
  USDU: <Usdu />,
  UNTD: <Untd />,
  BNB: <Bnb />,
  STBU: <Stbu />,
  GXAG: <Gxag />,
  TON: <Ton />,
  XAUT: <Xaut />,
  EURT: <Eurt />,
  DOGS: <Dogs />,

  //Fiat Icons
  ...fiatIcons,
};

export const CHART_PERIODS = [
  { id: 1, label: "24H", value: "1" },
  { id: 2, label: "7D", value: "7" },
  { id: 3, label: "14D", value: "14" },
  { id: 4, label: "30D", value: "30" },
  { id: 5, label: "90D", value: "90" },
  { id: 6, label: "180D", value: "180" },
];

export const CHART_TYPE: { [k: string]: ChartType } = {
  LINE: "LINE",
  CANDLE: "CANDLE",
};

export const coinsSelectNames = {
  BTC: "bitcoin",
  ETH: "ethereum",
  MATIC: "matic-network",
  USDT: "tether",
  USDC: "usd-coin",
  TRX: "tron",
};

export const FIAT_OPTIONS = [
  {
    id: "usd",
    value: { id: "USD" },
    min: 30,
    max: 5000,
    isFiat: true,
  },
  {
    id: "eur",
    value: { id: "EUR" },
    min: 30,
    max: 5000,
    isFiat: true,
  },
];

export const coinsMarketsIds =
  "bitcoin,ethereum,tether,tron,usd-coin,matic-network";

interface CardDesignInfo {
  name: string;
  image: string;
  bgColor: string;
}
export const virtualCardDesigns: { [k: string]: CardDesignInfo } = {
  CA05: {
    name: "Global Unit Pay",
    image: physicalStandardGTI,
    bgColor: "#121745",
  },
  CA10: {
    name: "Vikings",
    image: virtualVikinksImage,
    bgColor: "#919090",
  },
  CA06: {
    name: "Physical Gold",
    image: physicalGoldGTI,
    bgColor: "#DF9027",
  },
  CC18: {
    name: "Physical Standard",
    image: virtualGupImage,
    bgColor: "#121745",
  },
  CA02: {
    name: "RWA Card",
    image: rwaCard,
    bgColor: "#121745",
  },
  CA07: {
    name: "Foxpay Card",
    image: foxpayCard,
    bgColor: "#330066",
  },
};

export const EeaCountries: string[] = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",

  // not EEA
  "Switzerland",
  "United Kingdom",
];

// export const paybisAvailableCoins: string[] =
//   process.env.REACT_APP_ENVIRONMENT === "dev"
//     ? [
//         "BTC-TESTNET",
//         "BNBSC-TESTNET",
//         "USDT-TRC20-SHASTA",
//         "USDC",
//         "SOL",
//         "ETH-SEPOLIA",
//         "ADA",
//         "DOT",
//         "MATIC-POLYGON-AMOY",
//         "DAI",
//         "LINK",
//         "UNI",
//         "TRX-SHASTA",
//       ]
//     : [
//         "BTC",
//         "BNB",
//         "USDT-TRC20",
//         "USDC",
//         "SOL",
//         "ETH",
//         "ADA",
//         "DOT",
//         "MATIC-POLYGON",
//         "DAI",
//         "LINK",
//         "UNI",
//         "TRX",
//       ];

export const paybisAvailableCoins: string[] = [
  "BTC",
  "BNB",
  "USDT-TRC20",
  "USDC",
  "SOL",
  "ETH",
  "ADA",
  "DOT",
  "MATIC-POLYGON",
  "DAI",
  "LINK",
  "UNI",
  "TRX",
];
