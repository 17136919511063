import { FC, ReactNode } from "react";
import { Switch, SwitchProps, FormControlLabel } from "@mui/material";
import styled from "styled-components";
import { colors, mainFont } from "helpers/consts";

interface StyledCheckboxProps extends SwitchProps {
  label?: string | ReactNode;
}

const StyledCheckbox: FC<StyledCheckboxProps> = ({
  label,
  style,
  ...props
}) => (
  <>
    <StyledFormControl
      control={<Switch {...props} />}
      label={label || ""}
      style={style}
    />
  </>
);

const StyledFormControl = styled(FormControlLabel)`
  margin: 0;
  padding-right: 2.4rem;
  border-radius: 0.8rem;
  min-height: 4.4rem;
  max-height: 4.4rem;
  border: 1px solid ${colors.gray_300};

  .MuiSwitch-track {
    background-color: ${colors.gray_600};
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${colors.primary_500};
  }

  .MuiSwitch-thumb {
    background-color: ${colors.gray_50};
  }
  .Mui-checked .MuiSwitch-thumb {
    background-color: ${colors.primary_500};
  }
  .MuiTypography-root {
    font-size: 1.4rem;
    font-family: ${mainFont};
    color: ${colors.gray_600};
    font-weight: 500;
    line-height: 1.2;
  }
`;

export default StyledCheckbox;
