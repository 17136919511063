import { makeAutoObservable } from "mobx";
import type {
  StakeStrategyInterface,
  AvailableTradeOptions,
  StakeInterface,
} from "helpers/types";

class Store {
  stakesAmount: number = 0;
  stakeSrategies: StakeStrategyInterface[] | null = null;
  stakeSrategiesCurrencies: AvailableTradeOptions[] | null = null;
  activeStakes: StakeInterface[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setStakeStrategies = (strategies: StakeStrategyInterface[]) => {
    this.stakeSrategies = strategies;
  };

  setStakeStrategiesCurrencies = (strategiesCurr: AvailableTradeOptions[]) => {
    this.stakeSrategiesCurrencies = strategiesCurr;
  };

  setStakesAmount = (amount: number) => {
    this.stakesAmount = amount;
  };

  setActiveStakes = (stakes: StakeInterface[]) => {
    this.activeStakes = stakes;
  };
}

const walletStore = new Store();
export default walletStore;
