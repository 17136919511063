import { FC } from "react";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { colors } from "helpers/consts";
import authRoutes from "./authNav";
import { UserStore, GlobalStore } from "stores";
import type { LangType } from "helpers/types";

const Navigation: FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = UserStore;
  const { lang } = GlobalStore;
  return (
    <Nav>
      {isAuthenticated &&
        authRoutes.map(({ path, key, label, icon }) => {
          return (
            <NavItem key={key} to={path} $lang={lang}>
              {icon && icon}
              {t(label)}
            </NavItem>
          );
        })}
    </Nav>
  );
};

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  margin-left: 1.4rem;
  max-width: fit-content;

  @media (min-width: 56em) {
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 4.4rem;
    max-height: 4.4rem;
    max-width: 100%;
  }
`;

interface NavItemInterface {
  $lang?: LangType;
}
const NavItem = styled(NavLink)<NavItemInterface>`
  color: ${colors.gray_600};
  padding: 0.8rem;
  transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  font-weight: 600;

  ${({ $lang }) => {
    if ($lang === "ru") {
      return `
      font-size: 1.2rem;
      
      > svg {
        min-width: 1.8rem;
        max-width: 1.8rem;
        margin-right: 0.6rem;
      }
    `;
    } else if ($lang === "es") {
      return `
        font-size: 1.2rem;

        > svg {
          min-width: 1.6rem;
          max-width: 1.6rem;
          margin-right: 0.6rem;
        }
      `;
    } else {
      return `
        font-size: 1.4rem;
         > svg {
           min-width: 2rem;
           max-width: 2rem;
           margin-right: 0.8rem;
         }
      `;
    }
  }}

  &.active {
    color: ${colors.primary_700};
    > svg path {
      stroke: ${colors.primary_700};
    }
  }

  > svg path {
      stroke: ${colors.gray_600};
      transition: stroke 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  &:hover,
  &:focus {
    color: ${colors.primary_700};

    > svg path {
      stroke: ${colors.primary_700};
    }
  }
`;

export default observer(Navigation);
