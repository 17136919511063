import { FC, useCallback, useEffect } from "react";
import { observer } from "mobx-react-lite";
import "./i18n.config";
import Routes from "routes/routes";
import { Layout } from "components";
import { getIsLogged } from "api/user";
import { UserStore, WalletStore } from "stores";
import { getWalletsList } from "api/wallet";
import { socket } from "api/socket";
import { getRequestedForms } from "api/form";
import type { WalletsListInterface } from "helpers/types";

const App: FC = () => {
  const { isAuthenticated } = UserStore;
  const { walletsList, setWalletsList } = WalletStore;

  useEffect(() => {
    getIsLogged();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      socket.connect();
      getWalletsList();
      getRequestedForms();
    }

    if (!isAuthenticated) {
      socket.disconnect();
    }

    return () => {
      socket.disconnect();
    };
  }, [isAuthenticated]);

  const balanceChange = useCallback(
    (data: WalletsListInterface) => {
      if (
        walletsList &&
        !walletsList.find(
          ({ currencyName }) => currencyName === data.currencyName
        )
      ) {
        const newWalletsList = [...walletsList, data];
        setWalletsList(newWalletsList);
        return;
      }

      const newWalletsList = walletsList
        ? [...walletsList].reduce(
            (acc: WalletsListInterface[], wallet: WalletsListInterface) => {
              if (data.currencyName === wallet.currencyName) {
                wallet.balance = data.balance;
              }
              acc.push(wallet);
              return acc;
            },
            []
          )
        : [data];

      setWalletsList(newWalletsList);
    },
    [walletsList, setWalletsList]
  );

  useEffect(() => {
    socket.on("connect", () => {
      console.log("WebSocket connected");
    });
  }, []);

  useEffect(() => {
    socket.on("BALANCE_CHANGED", balanceChange);

    return () => {
      socket.off("BALANCE_CHANGED", balanceChange);
    };
  }, [balanceChange]);

  return (
    <Layout>
      <Routes />
    </Layout>
  );
};

export default observer(App);
