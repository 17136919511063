import { FC } from "react";
import styled from "styled-components";
import { currencyIcons } from "helpers/consts";

import { ReactComponent as NAIcon } from "assets/icons/coins/n_a.svg";

interface PairCellProps {
  from: string;
  to: string;
  forModal?: boolean;
}

const PairCoins: FC<PairCellProps> = ({ from, to, forModal }) => {
  console.log(currencyIcons[from], currencyIcons[to]);
  return (
    <CoinsContainer $forModal={forModal}>
      {currencyIcons[from] || <NAIcon />} {currencyIcons[to] || <NAIcon />}
    </CoinsContainer>
  );
};

interface CoinsContainerProps {
  $forModal?: boolean;
}

const CoinsContainer = styled.div<CoinsContainerProps>`
  display: flex;
  min-width: ${({ $forModal }) => ($forModal ? `9.6rem` : "6.8rem")};
  min-height: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
  max-height: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
  max-width: ${({ $forModal }) => ($forModal ? `9.6rem` : "6.8rem")};
  position: relative;

  & > *,
  & > * > svg {
    position: absolute;
    min-width: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
    max-width: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
    min-height: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
    max-height: ${({ $forModal }) => ($forModal ? `5.6rem` : "4rem")};
    top: 0;
  }
  & > *:first-child {
    left: 0;
  }
  & > *:last-child {
    right: 0;
  }
}
`;

export default PairCoins;
